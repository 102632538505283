
import { Component, Vue } from 'vue-property-decorator'
import { TrashcanDetail } from '../../types/trash'

@Component({
  name: 'CanDetail'
})
export default class CanDetail extends Vue {
  private tableData: Array<TrashcanDetail> = []
  private deviceId = ''
  private loading = false

  created () {
    this.deviceId = this.$route.params.deviceId as string
    this.getData()
  }

  getData (): void {
    this.loading = true
    this.$axios.get(this.$apis.trash.selectTrashcanByDeviceId, {
      deviceId: this.deviceId
    }).then(res => {
      this.tableData = []
      if (res.trashcan1) {
        this.tableData.push(res.trashcan1)
      }
      if (res.trashcan2) {
        this.tableData.push(res.trashcan2)
      }
      if (res.trashcan3) {
        this.tableData.push(res.trashcan3)
      }
      if (res.trashcan4) {
        this.tableData.push(res.trashcan4)
      }
      if (res.trashcan5) {
        this.tableData.push(res.trashcan5)
      }
      if (res.trashcan6) {
        this.tableData.push(res.trashcan6)
      }
      console.log(this.tableData)
    }).finally(() => {
      this.loading = false
    })
  }
}
